

















































import { Toaster } from '@/constant/Toaster';
import { AxiosResponse } from 'axios';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Action } from 'vuex-class';

@Component({
  name: 'informationForm',
  components: {},
  mixins: [],
})
export default class informationForm extends Vue {
  @Action('SubmitHelp') SubmitHelp!: (form: FormData) => Promise<any>;

  public onLoad = false;

  public created(): void {
    // on componenet created
  }
  public mounted(): void {
    // on componenet mounted
  }

  /**
   * OnSubmit
  */
  public OnSubmit(ev: Event) {
    this.onLoad = true;
    let form = new FormData(ev.target as HTMLFormElement);

    this.SubmitHelp(form).then((data) => {
      let success = data.code === 200;
      if (success) (ev.target as HTMLFormElement).reset();
      Toaster.Make(success ? "Terima kasih!" : (data.message || "Gagal"), { title: success ? "Berhasil" : "Gagal", variant: success ? "success" : "warning" });
    }).catch((err) => Toaster.Warning(err)).finally(() => this.onLoad = false);
  }
}
